exports.linkResolver = function linkResolver(doc) {
  // Route for blog posts
  if (doc.type === "post") {
    return `/${doc.uid}`
  }
  if (doc.type === "category") {
    return `/category/${doc.uid}`
  }
  // Homepage route fallback
  return "/"
}