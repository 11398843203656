module.exports = [{
      plugin: require('/opt/build/repo/app/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Feldmann trommelt","short_name":"feldmann","start_url":"/","background_color":"#ffffff","theme_color":"#ff0000","display":"minimal-ui","icon":"src/images/favicon.jpg","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"256841276bbbff5e851ca79420e94ef5"},
    },{
      plugin: require('/opt/build/repo/app/node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"feldmann-trommelt","shortenUrlLangs":true,"defaultLang":"en-us","langs":["en-us"],"previews":false,"pages":[{"type":"Post","match":"/:uid","path":"/post","component":"/opt/build/repo/app/src/templates/post.js"},{"type":"Page","match":"/:uid","path":"/page","component":"/opt/build/repo/app/src/templates/page.js"}]},
    },{
      plugin: require('/opt/build/repo/app/node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"UA-152009362-1","anonymize":true},"environments":["production","development"]},
    },{
      plugin: require('/opt/build/repo/app/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
